import React, { useState } from "react";
import "../css/styles.css";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import validator from "validator";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [Name, setName] = useState("");
  const [Contact, setContact] = useState("");
  const [Email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [contactError, setContactError] = useState("");
  const [showNameError, setShowNameError] = useState(true);
  const [showContactError, setShowContactError] = useState(true);

  const notify = () =>
    toast.success("Success!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleDetails = async () => {
    let detail = { Name, Contact, Email };
    try {
      await axios
        .post(
          "https://imvm3ppckk.execute-api.eu-central-1.amazonaws.com/default/SmartBell_Website_Contact_Lambda",
          detail
        )
        .then((res) => {
          console.log(res);
          // setName("");
          // setContact("");
          // setEmail("");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const validate = () => {
    setShowNameError(true);
    setShowContactError(true);
    setNameError("");
    setContactError("");
    setEmailError("");
    if (Name == "" && Contact == "" && Email == "") {
      setNameError("Please Enter Name");
      setContactError("Please Enter Contact No.");
      setEmailError("Please Enter Email");
    } else if (Name == "" && Contact == "" && !validator.isEmail(Email)) {
      setNameError("Please Enter Name");
      setContactError("Please Enter Contact No.");
      setEmailError("Enter Valid Email!");
    } else if (Contact == "" && Email == "") {
      setShowNameError(false);
      setContactError("Please Enter Contact No.");
      setEmailError("Please Enter Email");
    } else if (Name == "" && Email == "") {
      setShowContactError(false);
      setNameError("Please Enter Name");
      setEmailError("Please Enter Email");
    } else if (Name == "" && Contact == "") {
      setNameError("Please Enter Name");
      setContactError("Please Enter Contact No.");
    } else if (Contact == "" && !validator.isEmail(Email)) {
      setShowNameError(false);
      setContactError("Please Enter Contact No.");
      setEmailError("Enter Valid Email!");
    } else if (Name == "" && !validator.isEmail(Email)) {
      setShowContactError(false);
      setNameError("Please Enter Name");
      setEmailError("Enter Valid Email!");
    } else if (Name == "") {
      setShowContactError(false);
      setNameError("Please Enter Name");
    } else if (Contact == "") {
      setShowNameError(false);
      setContactError("Please Enter Contact No.");
    } else if (Email == "") {
      setEmailError("Please Enter Email");
    } else if (!validator.isEmail(Email)) {
      setEmailError("Enter valid Email!");
    } else {
      setName("");
      setContact("");
      setEmail("");
      handleDetails();
      notify();
      // setNameError("");
      // setContactError("");
      // setEmailError("");
    }
  };

  return (
    <>
      <section className="footer">
        <div
          className="
            container
           contact-div
          "
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h4 style={{ color: "white", fontSize: "40px" }}>
              Show your interest
            </h4>
          </div>

          <div className="contact-form-ipad">
            <div className="name-contact" style={{ alignItems: "center" }}>
              <div
                className={`${
                  showNameError ? "name-contact-error" : "contact-name-error"
                }`}
              >
                <label className="label field-size-mobile">
                  Name
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="text-field new-text-field"
                    placeholder="Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <span
                  style={{
                    color: "red",
                    marginLeft: "8px",
                  }}
                >
                  {nameError}
                </span>
              </div>
              <div
                className={`${
                  showContactError ? "name-contact-error" : "contact-name-error"
                }`}
              >
                <label className="label field-size-mobile">
                  Contact No.
                  <PhoneInput
                    country={"in"}
                    value={Contact}
                    onChange={(e) => setContact(e)}
                  />
                </label>
                <span
                  style={{
                    color: "red",
                    marginLeft: "8px",
                  }}
                >
                  {contactError}
                </span>
              </div>
            </div>
            <div className="email-button email-web-mobile">
              <label className="label field-size-mobile">
                Email
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="text-field new-text-field"
                  placeholder="Email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <span
                style={{
                  color: "red",
                  marginLeft: "8px",
                }}
              >
                {emailError}
              </span>
            </div>
            <div className="send-button">
              <button
                type="submit"
                className="submit-button email-button"
                onClick={validate}
                style={{
                  background: "#2EAEE9",
                  color: "black",
                  display: "block",
                }}
              >
                Send
              </button>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Bootstrap Bundle with Popper --> */}
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossorigin="anonymous"
      ></script>
    </>
  );
};

export default Contact;
