import React from "react";
import Footer from "./Footer";
import Contact from "./Contact";

const Layout = ({ children }) => {
  return (
    <>
      {children}
      <Contact />
      <Footer />
    </>
  );
};
export default Layout;
