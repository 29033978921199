import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../css/styles.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { ReactSVG } from "react-svg";
import habilelabs_logo from "../assets/new-habilelabs-logo.svg";
import google_play from "../assets/Group-484.svg";
import app_store from "../assets/Group-492.svg";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <div
          className="
            container
            text-center
            d-flex
            flex-column
            align-items-center
            justify-content-center
          "
        >
          <h2
            id="Download Now"
            className="F600 mb-3"
            style={{ color: "white" }}
          >
            Download Now
          </h2>
          <h3
            className="F18 mb-4 heading"
            style={{ color: "white", width: "80%" }}
          >
            We develop world-changing technology solutions to create a more
            inclusive, trustworthy, and sustainable digital society.
          </h3>
          <div className="d-flex align-items-center justify-content-center">
            <a
              href="https://play.google.com/store/apps/details?id=io.habilelabs.esp_school_bell"
              target="_blank"
              class="btn"
            >
              <img
                src={google_play}
                className="google-app-footer"
                alt="Google Play"
              />
            </a>
            <a href="#" class="btn">
              <img
                src={app_store}
                className="google-app-footer"
                alt="App Store"
              />
            </a>
          </div>
        </div>
        <div className="web-lower-footer mobile-lower-footer align-items-center">
          <div>
            <img src={habilelabs_logo} height="50px" width="200px" />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="contact-us-center"
          >
            <span className="contact">
              <a
                href="https://www.habilelabs.io/contact-us"
                style={{ color: "white" }}
                target="_blank"
              >
                Contact Us
              </a>
            </span>
          </div>
          <div>
            <span>
              <a
                href="https://www.instagram.com/habilelabs_pvt_ltd/"
                style={{ color: "#2EAEE9", padding: "0px 10px" }}
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </span>
            <span>
              <a
                href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQF8iUTC8GjyBAAAAYIVHUzQKpysgmzNADj9XoHIZKXb9ZEFrxhY-wElupfdJiNpIITFkUfL2xUYgXwKVFhQgZjrjR3xuUWiSnsqiZIb0_aH3IvyNIIYCvwL9AqHPkBo0XgKkBw=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fhabilelabs%2F"
                style={{ color: "#2EAEE9", padding: "0px 10px" }}
                target="_blank"
              >
                <LinkedInIcon />
              </a>
            </span>
            <span>
              <a
                href="https://www.facebook.com/habilelabs/"
                style={{ color: "#2EAEE9", padding: "0px 10px" }}
                target="_blank"
              >
                <FacebookIcon />
              </a>
            </span>
            <span>
              <a
                href="https://twitter.com/habilelabs"
                style={{ color: "#2EAEE9", padding: "0px 10px" }}
                target="_blank"
              >
                <TwitterIcon />
              </a>
            </span>
          </div>
        </div>
      </section>

      {/* <!-- Bootstrap Bundle with Popper --> */}
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossorigin="anonymous"
      ></script>
    </>
  );
};

export default Footer;
